<template>
	<div>
		<s-crud
			title="Configuración Epps"
			:config="config"
			:filter="filter"
			add
			edit
			remove
			@save="save($event)"
			ref="gridConfigEpps"
            @rowSelected="rowSelected($event)" 
			searchInput
		>
			<template v-slot:filter>
				<v-container>
					<v-row>
						<v-col class="s-col-form ma-3" lg="4" md="4" sm="6" xs="12">
                            <s-select-position
                            label="Cargo"
                            v-model="filter.PstID"
                            ></s-select-position>
                        </v-col> 
					</v-row>
				</v-container>
			</template>

			<template scope="props">
				<v-container>
					<v-row justify="center">
                        <v-col class="s-col-form" lg="4" md="4" sm="6" xs="12">
                            <!-- <s-text v-if="Type == 3" :maxlength="100" autofocus label="Descripción" ref="DerDescription" v-model="props.item.ArtName"  /> -->
                            <s-article-search   v-model="article"  :placeholder="props.item.ArtName" />
                        </v-col>
                        <v-col class="s-col-form" lg="4" md="4" sm="6" xs="12">
                            <s-select-position
                            label="Cargo"
                            v-model="props.item.PstID"
                            :text="props.item.PstName"
                            ></s-select-position>
                        </v-col>
					
					</v-row>
				</v-container>
			</template>

			<template v-slot:Action="{ row }" v-if="$fun.isAdmin()">
				<v-btn 
					dark
					x-small 
					elevation="0" 
					@click="showRowData(row)" 
					:color="row.CseStatus === 0 ? 'secondary' : 'red'">
					<v-icon x-small :color="'black'" v-if="row.CseStatus === 0">
						fas fa-check
					</v-icon>
					<v-icon  x-small v-else>
						mdi-close
					</v-icon>
					<span :style="{ color: row.CseStatus === 0 ? 'black' : 'white' }">
						{{ row.CseStatus === 0 ? 'Aprobar' : 'Desaprobar' }}
					</span>
				</v-btn>
			</template>

			<template v-slot:CseStatus="{ row }">
				<v-chip style="margin:0px" x-small :color="row.CseStatus == 1 ? 'success' : 'error'">
					{{ row.CseStatus == 1 ? "Aprobado" : "Desaprobado" }}
				</v-chip>
			</template>
		</s-crud>

	</div>
</template>

<script>
	import _sCttoService from '@/services/HumanResource/HmnConfigSegurityEqService.js';
    import sArticleSearch from "@/components/Utils/Logistics/SArticleSearch";
	import { isUndefined } from 'lodash';

	export default {
        components: { sArticleSearch,  },
		data() {
			return {
                ArtCode:null,
                ArtName:null,
                article: null,
				selected : {},
				filter: {
					PrsID: 0,
					IsAdmin: this.$fun.isAdmin() ? 1 : 0
				},
				config: {
					model: {
                        CseID: "ID",
						Action: "", 
						CseStatus: "status",
					},
					service: _sCttoService,
					headers: [
						{text: "ID", value: "CseID"},
						{text: "Acciones", value: "Action", width: 120},
						{text: "Cargo", value: "PstName"},
						{text: "Articulo", value: "ArtName"},
						{text: "Estado", value: "CseStatus"}
					]
				},
				
			}
		},
        watch : {
			filter(){
				console.log(this.filter)
			},
            article() {
                if (this.article && this.article.ArtCode) {
                    this.ArtCode = this.article.ArtCode;
					this.ArtName=this.article.ArtName;
                    console.log("articleCode", this.ArtCode);   
                }
           },
		},
		methods: {
			initialize()
			{
			},
			save(item) {
				let obj = {
					CseID: item.CseID,
					ArtCode:this.ArtCode,
					ArtName:this.ArtName,
					PstID: item.PstID,
					SecStatus: 1,
					UsrCreateID: this.$fun.getUserID(),
					UsrUpdateID: this.$fun.getUserID(),
					CseStatus: item.CseStatus,
				}
				_sCttoService.save(obj, this.$fun.getUserID())
				.then(resp => {
					if(resp.status == 200){
						this.$fun.alert("Agreado correctamente", "success");
						this.$refs.gridConfigEpps.refresh();  
					}
				});
			},
            rowSelected(items)
			{
				if(items.length > 0)
				{
					this.selected = items[0];
                    this.article=null;
                    this.ArtCode=items[0].ArtCode;
					this.ArtName=items[0].ArtName;
                    console.log("rowCode",this.ArtCode);
				}
			},
			showRowData(row) {
				row.UsrUpdateID = this.$fun.getUserID();
				this.$fun.alert("Seguro de guardar?", "question").then((r) => {
					if (r.value) {

						const newStatus = row.CseStatus === 0 ? 1 : 0;
						this.$set(row, 'CseStatus', newStatus);

						_sCttoService
							.save(row, this.$fun.getUserID())
							.then((response) => {
								if (response.data.length > 1) {

								} else {
									this.$fun.alert("Guardado Correctamente", "success");
									this.$refs.gridConfigEpps.refresh();
								}
							});
					}
				});
			},
		},
	}
</script>